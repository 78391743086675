// @flow

import React from "react";
import { Route, Switch } from "react-router-dom";
import "fomantic-ui-css/semantic.css";

import Contact from "./pages/contact/Contact";
import Header from "./pages/common/header/Header";
import Home from "./pages/home/Home";
import Photos from "./pages/photos/Photos";

import "./App.css";

const App = () => (
  <>
    <Header />
    <Switch>
      <Route exact path={["/", "/index.html"]} component={Home} />
      <Route path="/photos" component={Photos} />
      <Route path="/contact" component={Contact} />
    </Switch>
  </>
);

export default App;
