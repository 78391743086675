import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, Client, dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import 'typeface-montserrat';

import App from './App';
import './index.css';

const GRAPHQL_HOST = 'https://api.neilallison.com';

const cache = cacheExchange();

const client = new Client({
  url: `${GRAPHQL_HOST}/graphql`,
  exchanges: [dedupExchange, cache, fetchExchange],
})

ReactDOM.render(
    <React.StrictMode>
        <Provider value={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
