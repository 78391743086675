// @flow

import React from "react";
import { Blurhash } from "react-blurhash";
import type { ProcessedImage } from "react-justified-grid";
import classNames from "classnames";

import styles from "./GridImage.module.css";

type Props = {|
  image: ProcessedImage,
|};

const BLURHASH_RES_Y = 128;

const GridImage = ({ image }: Props) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const { src, width, height, left, alt, originalData } = image;

  return (
    <>
      <img
        className={classNames({
          [styles.imageWrapper]: true,
          [styles.padded]: left > 0,
          [styles.hidden]: !imageLoaded,
        })}
        width={width}
        height={height}
        src={src}
        onLoad={() => setImageLoaded(true)}
        alt={alt || ""}
      />
      <div
        className={classNames({
          [styles.imageWrapper]: true,
          [styles.padded]: left > 0,
          [styles.hidden]: imageLoaded,
        })}
      >
        <Blurhash
          hash={originalData.blurhash}
          width={width}
          height={height}
          resolutionX={Math.floor(BLURHASH_RES_Y * (width / height))}
          resolutionY={BLURHASH_RES_Y}
          punch={1}
        />
      </div>
    </>
  );
};

export default GridImage;
