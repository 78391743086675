// @flow

import React from "react";
import { useQuery } from "urql";
import shuffle from "shuffle-array";
import PhotoGrid from "../common/photo/PhotoGrid";

import styles from "./Home.module.css";

const AllPhotosQuery = `
	query {
		allPhotos {
			id
			url
			blurhash
			uploaded
			exif {
				camera
				lens
				width
				height
				focalLength
				shutterSpeed
				aperture
				iso
			}
		}
	}
`;

const Home = () => {
  const [result] = useQuery({
    query: AllPhotosQuery,
  });
  const { data } = result;

  const gridPhotos = data
    ? shuffle(data.allPhotos).map((photo) => ({
        id: photo.id,
        src: photo.url,
        width: photo.exif.width,
        height: photo.exif.height,
        blurhash: photo.blurhash,
      }))
    : [];

  return (
    <div className={styles.homeWrapper}>
      <PhotoGrid photos={gridPhotos} />
    </div>
  );
};

export default Home;
