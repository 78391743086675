// @flow

import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HeaderLink from "./HeaderLink";

import styles from "./Header.module.css";

const NAME_LOGO_STR = "<b>&lt;</b> NEIL ALLISON [ Ō ] <b>/&gt;</b>";
const TYPING_SPEED = 90;

const Header = () => {
  const nameLogoRef = useRef();

  useEffect(() => {
    if (nameLogoRef.current) {
      nameLogoRef.current.innerHTML = "";

      let cursorPosition = 0,
        curChar: string = "",
        encodedChar = "",
        tag: any = "",
        isWritingEncoded = false,
        isWritingTag = false,
        isTagOpen = false;

      const type = () => {
        curChar = NAME_LOGO_STR[cursorPosition];
        if (isWritingTag) {
          tag += curChar;
        }

        if (curChar === "&") {
          isWritingEncoded = true;
          encodedChar = curChar;
        } else {
          if (curChar === "<") {
            if (isTagOpen) {
              isTagOpen = false;
              isWritingTag = true;
            } else {
              tag = "";
              isTagOpen = true;
              isWritingTag = true;
              tag += curChar;
            }
          }

          if (!isWritingTag && isTagOpen) {
            if (isWritingEncoded) {
              encodedChar += curChar;
              if (curChar === ";") {
                isWritingEncoded = false;
                const tmpTextArea = document.createElement("textarea");
                tmpTextArea.innerHTML = encodedChar;
                tag.innerHTML += tmpTextArea.value;
              }
            } else {
              tag.innerHTML += curChar;
            }
          }

          if (!isWritingTag && !isTagOpen) {
            nameLogoRef.current.innerHTML += curChar;
          }

          if (isWritingTag && curChar === ">") {
            isWritingTag = false;
            if (isTagOpen) {
              const newSpan = document.createElement("span");
              nameLogoRef.current.appendChild(newSpan);
              newSpan.innerHTML = tag;
              tag = newSpan.firstChild;
            }
          }
        }

        cursorPosition++;
        if (cursorPosition < NAME_LOGO_STR.length - 1) {
          setTimeout(type, TYPING_SPEED);
        }
      };

      type();
    }
  }, []);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.nameWrapper}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className={styles.nameLogo} ref={nameLogoRef} />
        </Link>
      </div>
      <div className={styles.linksWrapper}>
        <HeaderLink to="/photos" text="PHOTOS" />
        <HeaderLink to="/contact" text="CONTACT" />
      </div>
    </div>
  );
};

export default Header;
