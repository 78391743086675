// @flow

import React from "react";
import { Link } from "react-router-dom";

import styles from "./HeaderLink.module.css";

type Props = {|
  to: string,
  text: string,
|};

const HeaderLink = ({ to, text }: Props) => (
  <Link to={to} style={{ textDecoration: "none" }}>
    <div className={styles.wrapper}>{text}</div>
  </Link>
);

export default HeaderLink;
