// @flow

import React from "react";
import styles from "./Contact.module.css";

const Contact = () => (
  <div className={styles.contactWrapper}>
    <p className={styles.contactText}>Questions? comments? Love it? Hate it?</p>
    <p className={styles.contactText}>Let me know what you think!</p>
    <br />
    <p className={styles.contactText}>
      <a href="mailto:neiljallison@gmail.com" className={styles.emailText}>
        neiljallison@gmail.com
      </a>
    </p>
  </div>
);

export default Contact;
