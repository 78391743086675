// @flow

import React from "react";
import JustifiedGrid from "react-justified-grid";
import GridImage from "./GridImage";

type Photo = {|
  id: string,
  src: string,
  width: number,
  heigth: number,
  blurhash: string,
|};

type Props = {|
  photos: Photo[],
  maxRowHeight?: number,
|};

const GRID_GUTTER = 12;

const PhotoGrid = ({ photos, maxRowHeight }: Props) => (
  <JustifiedGrid
    images={photos}
    maxRowHeight={maxRowHeight}
    gutter={GRID_GUTTER}
    showIncompleteRow
  >
    {(processedImages) => {
      return (
        <>
          {processedImages.map((image) => (
            <GridImage image={image} key={image.originalData.id} />
          ))}
        </>
      );
    }}
  </JustifiedGrid>
);

PhotoGrid.defaultProps = {
  maxRowHeight: 800,
};

export default PhotoGrid;
